<template>
  <div class="tiledContainer">
    <!--manually weather switcher, for dev purposes-->
    <div class="scene-controls-panel">
      <label>
        <span>{{ $t('tiledSceneWeather') }}</span>
        <input v-model="weather" type="number" step="1" min="0" max="12" />
      </label>
    </div>

    <div ref="scenesWrapper" v-loading="loading" class="scenesWrapper">
      <div class="scenes-container" :class="{ 'night-mode': isNightModeOn }">
        <div id="pixiContainer" ref="pixiContainer" class="pixiContainer" />
      </div>
    </div>

    <el-dialog
      v-model="showBuyPearlModal"
      class="crafting"
      :class="'without-header'"
      :fullscreen="isMobile"
      :title="!isCraftingBuilding ? $t(selectedBuilding?.buildingKey || '') : undefined"
      :append-to-body="true"
      :center="true"
      @closed="selectedBuilding = null"
    >
      <BuyPearlModal />
    </el-dialog>

    <el-dialog
      v-if="tokens"
      v-model="showStorageModal"
      class="crafting"
      :class="'without-header'"
      :fullscreen="isMobile"
      :title="!isCraftingBuilding ? $t(selectedBuilding?.buildingKey || '') : undefined"
      :append-to-body="true"
      :center="true"
      @closed="selectedBuilding = null"
    >
      <StorageMobile v-if="$device.isMobile || $device.isTablet" :tokens="tokens" @close="selectedBuilding = null" />
      <StorageDesktop v-else :tokens="tokens" @close="selectedBuilding = null" />
    </el-dialog>

    <el-dialog
      v-model="showCraftingModal"
      class="crafting produce"
      :class="'without-header'"
      :fullscreen="isMobile"
      :title="!isCraftingBuilding ? selectedBuilding?.buildingName : undefined"
      :append-to-body="true"
      :center="true"
      @closed="selectedBuilding = null"
    >
      <CraftingModal v-if="selectedBuilding" :selected-building="selectedBuilding" />
    </el-dialog>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch, type Ref, type UnwrapRef } from 'vue';
import { DisplayObject, FederatedPointerEvent, type ColorSource, type ITextStyle, type TextStyle } from 'pixi.js';
import { Container, Graphics, Text, Assets, Sprite } from 'pixi.js';
import type TiledMap from 'tiled-tmj-typedefs/types/TiledMap';
import { tiledToPixi } from '~/utils/animations';
import { apiUrls, ROUTES_WITHOUT_WEATHER_EFFECTS } from '~/utils/constants';
import { AdjustmentFilter } from '@pixi/filter-adjustment';
import type { Viewport } from 'pixi-viewport';
import { getPixiWorldSizes } from '~/utils';
import { isInteractiveObject, isPredictOrNull } from '~/types/typequards';
import type { TNullable } from '~/types/common';
import type { BuildingProps } from '~/types/crafting';
import type { Object, Tile } from 'tiled-tmj-typedefs/types/TiledMap';
import { useDevice, usePIXIAppInstance, useRoute } from '#imports';
import { CraftingModal } from '#components';
import type { Spine } from 'pixi-spine';
import type { ITokenModel } from '~/types/apiService';
import { useI18n } from '#imports';
import type { InteractiveObject } from '~/types/tiledScene';

const emit = defineEmits(['resetAnimation']);
const props = defineProps<{ tilemap: TiledMap; animation?: string }>();
const route = useRoute();
const { isMobile } = useDevice();
const { apiUrl } = useEnvs();
const { t, locale } = useI18n();

const pixiContainer = ref(null);
const scenesWrapper = ref(null);
const selectedBuilding = ref<TNullable<BuildingProps>>(null);
const isCraftingBuilding = computed(
  () => selectedBuilding.value && ['gold_ore_smelter'].includes(selectedBuilding.value?.buildingKey)
);

const showCraftingModal = computed(() => !!(selectedBuilding.value && isCraftingBuilding.value));
const showBuyPearlModal = computed(() => !!(selectedBuilding.value && selectedBuilding.value?.buildingKey === 'dock'));
const showStorageModal = computed(
  () => !!(selectedBuilding.value && tokens && selectedBuilding.value?.buildingKey === 'warehouse')
);

const PIXI_WORLD_SIZES = getPixiWorldSizes(props.tilemap, document.body as HTMLDivElement);
const { app, viewport } = usePIXIAppInstance(PIXI_WORLD_SIZES);

const main = useMainStore();
const { setRandomWeatherValue } = main;

const loading = ref(false);
const isNightModeOn = ref(false);
const isLightningModeOn = ref(false);
const lightningThresholdA = ref(9980);
const lightningThresholdB = ref(9970);
const lightning1 = ref(0);
const lightning2 = ref(0);
const scriptIsRunning = ref(false);
const rainType = ref(0);
const sceneSaturation = ref(0.1);
const rainSprite = ref<Sprite | null>(null);
const lightningInterval = ref(0);
const lightningALayer = ref<Sprite | null>(null);
const currentLightningOpacityValue = ref(0);
const interactiveObjects = ref<InteractiveObject[]>([]);
const buildingNameSprites = ref<InstanceType<typeof Text>[]>([]);
const tooltipWrappers = ref<Graphics[]>([]);
const producedTokensIcons = ref<Array<Sprite[]>>([]);

const weather = ref(0);
setRandomWeatherValue();
weather.value = main.weatherValue;

const intervalWeather = setInterval(() => setRandomWeatherValue(), 1800000);

const { data: tokens } = await useFetch<ITokenModel[]>(apiUrls.token.tokens, {
  baseURL: apiUrl,
  transform: (data) => data.sort((a, b) => a.displayOrder - b.displayOrder)
});

const isShowWeatherEffects = computed(() => {
  return ROUTES_WITHOUT_WEATHER_EFFECTS.story !== route.name;
});

// hardcoded data, for test only
const TOKENS: { icon: string; claimAmount: number }[] = [
  // { icon: '/img/tokens/gold.png', claimAmount: 0 },
  // { icon: '/img/tokens/coal.png', claimAmount: 0 }
];
const TEXT_ELEMENT_PADDING = { x: 60, y: 25 };
const FONT_SIZE = 70;
const ICON_SIZE = 80;

watch(showCraftingModal, (newValue) => {
  viewport.pause = !!newValue;
  if (newValue) {
    app.ticker.stop();
  } else {
    app.ticker.start();
  }
});
// Watch for changes in the weather value
watch(weather, () => {
  if (isShowWeatherEffects.value) {
    // Apply weather effects
    weatherEffects();
  }
  if (isLightningModeOn.value) {
    setLightningEffect();
  }
  // Check if the app instance is of type Application (Pixi.js)
  if (app.renderer && app.stage && app.ticker && viewport && viewport instanceof Container) {
    updateSpineAnimationsForWeather(viewport, props.tilemap, weather.value);
  }
});

watch(interactiveObjects, (newInteractiveObjects: UnwrapRef<InteractiveObject>[]) => {
  if (!newInteractiveObjects.length) {
    return;
  }

  const buildingObjects = newInteractiveObjects.map(({ tileObject }) => tileObject);

  newInteractiveObjects.forEach(({ tileObject, spriteElement }) => {
    buildingHoverHandler(tileObject, spriteElement as Spine | Sprite);
  });

  createBuildingsTooltips(buildingObjects);
});

watch(locale, () => {
  const buildingObjects = interactiveObjects.value.map(({ tileObject }) => tileObject);

  buildingObjects.map((element, i) => {
    const tokenIcons: UnwrapRef<Sprite[]> = producedTokensIcons.value[i];
    buildingNameSprites.value[i].text = getTextStr(TOKENS, tokenIcons, element);
  });

  destroyTooltips();
  createBuildingsTooltips(buildingObjects);
});

watch(props, (newValue) => {
  if (newValue && app.stage && viewport instanceof Container && newValue?.animation) {
    restartAnimation(viewport, props.tilemap, weather.value, newValue.animation);
    emit('resetAnimation');
  }
});
onBeforeUnmount(() => {
  viewport?.destroy();
  app?.ticker?.destroy();
  app?.renderer?.destroy();
  app?.stage?.destroy();
  if (lightningInterval.value) {
    clearInterval(lightningInterval.value);
  }
  clearInterval(intervalWeather);
  document.removeEventListener('wheel', documentWheelListenerHandler);
  window.removeEventListener('resize', documentResizeListenerHandler);
});

onMounted(async () => {
  await nextTick();
  document.addEventListener('wheel', documentWheelListenerHandler, { passive: false });
  window.addEventListener('resize', documentResizeListenerHandler);

  loading.value = true;

  //@ts-ignore
  pixiContainer.value?.appendChild(app.view);

  // Convert and display Tiled map using Pixi.js
  interactiveObjects.value = await tiledToPixi(
    viewport as Viewport,
    props.tilemap,
    '/assets/new_scenes/',
    weather.value
  );

  // Set loading state to false
  loading.value = false;

  if (isShowWeatherEffects.value && !lightningInterval.value) {
    // Apply weather effects
    weatherEffects();
    // Set lightning effect
    setLightningEffect();
  }
});

const buildingHoverHandler = (tile: Object | Tile, targetObject: Spine | Sprite) => {
  const isHoverable = tile?.properties?.find((property) => property.name === 'isHoverable');
  const tintColor = tile?.properties?.find((property) => property.name === 'tintColor');
  const buildingName = tile?.properties?.find((property) => property.name === 'buildingName');
  const buildingHash = tile?.properties?.find((property) => property.name === 'buildingHash');
  const buildingKey = tile?.properties?.find((property) => property.name === 'buildingKey');
  const descriptionKey = tile?.properties?.find((property) => property.name === 'descriptionKey');

  const index = interactiveObjects.value.findIndex((item) => item.tileObject.id === tile.id);

  if (!isHoverable) {
    return;
  }

  targetObject.eventMode = 'dynamic';
  targetObject.cursor = 'pointer';

  targetObject.on('pointerdown', () => {
    const buildingObject = {
      buildingName: buildingName?.value,
      buildingHash: buildingHash?.value,
      buildingKey: buildingKey?.value,
      descriptionKey: descriptionKey?.value
    };

    selectedBuilding.value = buildingObject as BuildingProps;
  });

  targetObject
    .on('mouseover', (e: FederatedPointerEvent) => {
      if (isInteractiveObject(e.currentTarget)) {
        e.currentTarget.tint = tintColor?.value as ColorSource;

        buildingNameSprites.value[index].alpha = 1;
        tooltipWrappers.value[index].alpha = 1;
      }
    })
    .on('mouseout', (e: FederatedPointerEvent) => {
      if (isInteractiveObject(e.currentTarget)) {
        e.currentTarget.tint = '0xFFFFFF';

        buildingNameSprites.value[index].alpha = 0;
        tooltipWrappers.value[index].alpha = 0;
      }
    });
};

const getTextStr = (
  tokens: { icon: string; claimAmount: number }[],
  tokenIcons: UnwrapRef<Sprite[]> | Sprite[],
  element: Object | Tile | Ref<UnwrapRef<InteractiveObject[]>>
): string => {
  if (tokenIcons?.length === 0) {
    const res = (element as Object)?.properties?.find((prop) => prop.name === 'buildingKey')?.value as string;
    return t(res);
  }

  if (tokenIcons?.length === 1) {
    return tokens[0].claimAmount.toString();
  }

  if (tokenIcons && tokenIcons?.length > 1) {
    return '';
  }

  const res = (element as Object)?.properties?.find((prop) => prop.name === 'buildingKey')?.value as string;
  return t(res);
};

const getProducedTokenIcons = async (
  buildings: (Object | Tile)[],
  tooltipsContainer: Container
): Promise<Array<Sprite[]>> => {
  if (!TOKENS.length) {
    return Promise.resolve([]);
  }

  const producedTokensIcons = await Promise.all(
    buildings.map(async (element) => {
      return Promise.all(
        TOKENS.map(async (token, i) => {
          const tokenAsset = await Assets.load(token.icon);
          const sprite = new Sprite(tokenAsset);
          sprite.width = ICON_SIZE;
          sprite.height = ICON_SIZE;

          sprite.position = {
            x: element.x + element.width / 3 + TEXT_ELEMENT_PADDING.x + i * ICON_SIZE,
            y: element.y - (element.height + TEXT_ELEMENT_PADDING.y * 2 + FONT_SIZE) + TEXT_ELEMENT_PADDING.y
          };
          sprite.zIndex = 1;

          tooltipsContainer.addChild(sprite);
          return sprite;
        })
      );
    })
  );

  return producedTokensIcons;
};

const destroyTooltips = () => {
  tooltipWrappers.value.map((tooltip) => {
    tooltip.destroy();
  });
};

const createTooltip = (tileObject: Tile | Object, textElement: UnwrapRef<Text>) => {
  const tooltip = new Graphics();

  tooltip.lineStyle({ width: 5, color: 0xffffff });
  tooltip.beginFill(0x081b25);
  tooltip.drawRoundedRect(
    tileObject.x + tileObject.width / 2 - textElement.width / 2,
    tileObject.y - (tileObject.height + TEXT_ELEMENT_PADDING.y * 2 + FONT_SIZE),
    textElement.width + 2 * TEXT_ELEMENT_PADDING.x + TOKENS.length * ICON_SIZE,
    textElement.height + 2 * TEXT_ELEMENT_PADDING.y,
    50
  );
  tooltip.endFill();
  tooltip.alpha = 0;

  return tooltip;
};

const createBuildingsTooltips = async (buildings: (Object | Tile)[]) => {
  const tooltipsContainer = new Container();
  tooltipsContainer.sortableChildren = true;
  tooltipsContainer.eventMode = 'static';
  viewport?.addChild(tooltipsContainer);

  const _producedTokensIcons = await getProducedTokenIcons(buildings, tooltipsContainer);
  producedTokensIcons.value = _producedTokensIcons;

  buildingNameSprites.value = buildings.map((element, i) => {
    const style: Partial<ITextStyle> | TextStyle = {
      fontSize: `${FONT_SIZE}px`,
      fontWeight: 'bold',
      fontFamily: 'Eczar, sans-serif',
      fill: 0xffffff,
      wordWrap: true,
      wordWrapWidth: isMobile ? 300 : 850,
      align: 'left'
    };

    // create the buildings tooltips
    const tokenIcons = _producedTokensIcons[i];
    const textSprite = new Text(getTextStr(TOKENS, tokenIcons, element), style);

    textSprite.position = {
      x: element.x + (element.width / 2 - textSprite.width / 2) + TEXT_ELEMENT_PADDING.x + TOKENS.length * ICON_SIZE,
      y: element.y - (element.height + TEXT_ELEMENT_PADDING.y * 2 + FONT_SIZE) + TEXT_ELEMENT_PADDING.y
    };
    textSprite.zIndex = 1;
    textSprite.alpha = 0;

    return textSprite;
  });

  tooltipWrappers.value = interactiveObjects.value.map(({ tileObject }, i) => {
    const textElement = buildingNameSprites.value[i];

    return createTooltip(tileObject, textElement);
  });

  buildingNameSprites.value.forEach((text) => {
    if (text instanceof DisplayObject) tooltipsContainer.addChild(text);
  });
  tooltipWrappers.value.forEach((wrapper) => {
    if (wrapper instanceof DisplayObject) tooltipsContainer.addChild(wrapper);
  });
};

const documentWheelListenerHandler = (e: WheelEvent) => {
  if (e.ctrlKey) {
    e.preventDefault();
    e.stopPropagation();
  }
};

const documentResizeListenerHandler = () => {
  const PIXI_WORLD_SIZES = getPixiWorldSizes(props.tilemap, scenesWrapper.value as unknown as HTMLDivElement);
  setTimeout(() => {
    viewport?.resize(...Object.values(PIXI_WORLD_SIZES.viewportSizes));
    app?.renderer?.resize(PIXI_WORLD_SIZES.rendererSize.width, PIXI_WORLD_SIZES.rendererSize.height);
    weatherEffects();
  }, 150);
};

const weatherEffects = async () => {
  // Apply weather effects based on weather conditions
  if (isPredictOrNull(rainSprite.value)) {
    //@ts-ignore

    app?.stage?.removeChild(rainSprite.value);
  }
  //@ts-ignore
  if (isPredictOrNull<Sprite>(lightningALayer.value)) {
    app?.stage?.removeChild(lightningALayer.value);
  }

  // Clear and calm weather conditions
  if (weather.value == 0) {
    isLightningModeOn.value = false;
    rainType.value = 0;
    sceneSaturation.value = 1;
  }
  if (weather.value == 1) {
    isLightningModeOn.value = false;
    rainType.value = 0;
    sceneSaturation.value = 0.98;
  }
  if (weather.value == 2) {
    isLightningModeOn.value = false;
    rainType.value = 0;
    sceneSaturation.value = 0.95;
  }
  // Mild weather conditions
  if (weather.value == 3) {
    isLightningModeOn.value = false;
    rainType.value = 0;
    sceneSaturation.value = 0.9;
  }
  if (weather.value == 4) {
    isLightningModeOn.value = false;
    rainType.value = 0;
    sceneSaturation.value = 0.9;
  }
  if (weather.value == 5) {
    isLightningModeOn.value = false;
    rainType.value = 0;
    sceneSaturation.value = 0.85;
  }
  if (weather.value == 6) {
    isLightningModeOn.value = false;
    rainType.value = 0;
    sceneSaturation.value = 0.8;
  }
  // Rainy weather conditions
  if (weather.value == 7) {
    isLightningModeOn.value = true;
    lightningThresholdA.value = 9997;
    lightningThresholdB.value = 9995;
    rainType.value = 1;
    sceneSaturation.value = 0.75;
  }
  if (weather.value == 8) {
    isLightningModeOn.value = true;
    lightningThresholdA.value = 9997;
    lightningThresholdB.value = 9995;
    rainType.value = 1;
    sceneSaturation.value = 0.7;
  }
  // Heavy rain weather conditions
  if (weather.value == 9) {
    isLightningModeOn.value = true;
    lightningThresholdA.value = 9992;
    lightningThresholdB.value = 9987;
    rainType.value = 2;
    sceneSaturation.value = 0.5;
  }
  if (weather.value == 10) {
    isLightningModeOn.value = true;
    lightningThresholdA.value = 9992;
    lightningThresholdB.value = 9987;
    rainType.value = 2;
    sceneSaturation.value = 0.4;
  }
  // Heavy rain weather conditions
  if (weather.value == 11) {
    isLightningModeOn.value = true;
    lightningThresholdA.value = 9992;
    lightningThresholdB.value = 9987;
    rainType.value = 2;
    sceneSaturation.value = 0.3;
  }
  // Stormy weather conditions
  if (weather.value == 12) {
    isLightningModeOn.value = true;
    lightningThresholdA.value = 9983;
    lightningThresholdB.value = 9975;
    rainType.value = 3;
    sceneSaturation.value = 0.2;
  }

  const saturationFilter = new AdjustmentFilter({
    saturation: sceneSaturation.value
  });

  if (app?.stage) {
    app.stage.filters = [saturationFilter];
  }

  // !!! Temporary disabled rain, please don`t delete !!!

  if (rainType.value > 0) {
    const { videoSprite, lightningLayer } = await createWeatherEffectsLayers(
      rainType.value,
      app.renderer.view.width,
      app.renderer.view.height
    );
    // store the current video sprite
    rainSprite.value = videoSprite;
    lightningALayer.value = lightningLayer;
    app.stage.addChild(lightningLayer);
    app.stage.addChild(videoSprite);
  }
};

// Set up the lightning effect by running the lightningEffect method at regular intervals
const setLightningEffect = () => {
  if (isLightningModeOn.value) {
    // Run lightningEffect every 20ms
    lightningInterval.value = Number(setInterval(lightningEffect, 20));
  }
};
// Perform the lightning effect
const lightningEffect = () => {
  // Check if the script is already running to prevent overlapping executions
  if (scriptIsRunning.value) {
    return false; // Return if the script is running to avoid concurrent execution
  } else {
    scriptIsRunning.value = true; // Set the script as running
  }

  // Define maximum opacity values for different lightning elements
  const lightning1max: number = 0.78431372549019607;
  const lightning2max: number = 0.58823529411764708;
  const overallLightningMax: number = 0.88235294117647056;

  // Get lightning opacity thresholds
  const lightning1Treshold: number = lightningThresholdA.value;
  const lightning2Treshold: number = lightningThresholdB.value;

  // Generate random numbers for comparison
  const randomNumber1: number = Math.random() * 10000;
  const randomNumber2: number = Math.random() * 10000;

  // Update lightning opacity based on thresholds and random numbers
  if (randomNumber1 > lightning1Treshold) lightning1.value = lightning1max;
  if (randomNumber2 > lightning2Treshold) lightning2.value = lightning2max;

  // Calculate combined lightning opacity
  let lightningOpacity: number = lightning1.value + lightning2.value;

  // Ensure the combined lightning opacity does not exceed the overall maximum
  if (lightningOpacity > overallLightningMax) {
    lightning1.value *= overallLightningMax / lightningOpacity;
    lightning2.value *= overallLightningMax / lightningOpacity;
    lightningOpacity = lightning1.value + lightning2.value;
  }

  if (lightningALayer.value) {
    // Get the current opacity of the element
    // Check if the current opacity is greater than 0
    if (currentLightningOpacityValue.value > 0 || lightningOpacity > 0) {
      // Adjust the opacity of the element
      lightningALayer.value.filters = [new AdjustmentFilter({ alpha: lightningOpacity })];
      currentLightningOpacityValue.value = lightningOpacity;
    }
  }

  // Update the lightning values for the next tick
  lightning1.value *= 0.5;
  lightning1.value -= 0.003921568627451;
  if (lightning2.value > 0) lightning2.value += 0.196078431372549 - Math.random() * 0.49019607843137247;

  // Clamp the lightning values to ensure they are within the valid range
  lightning1.value = Math.max(0, lightning1.value);
  lightning2.value = Math.max(0, lightning2.value);
  lightning1.value = Math.min(1, lightning1.value);
  lightning2.value = Math.min(1, lightning2.value);

  // Set the script as not running after completion
  scriptIsRunning.value = false;
};
</script>

<style scoped lang="scss">
header {
  height: 0px;
}

.tiledContainer {
  height: calc(100dvh - 117px);
  position: relative;
}

.pixiContainer {
  position: relative;
  line-height: 0;
  height: calc(100dvh - 117px);

  /* transition: 10ms ease all; */
}

.scenesWrapper {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  height: calc(100dvh - 117px);
  width: 100%;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.scenes-container {
  height: calc(100dvh - 117px);
  width: fit-content;
}

.night-mode canvas {
  filter: brightness(0.7) contrast(1.1);
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: 500ms ease all;
  mix-blend-mode: multiply;
}

.daytime-filter-overlay {
  opacity: 1;
  background-color: #1c0e9de0;
  mix-blend-mode: multiply;
  /* pointer-events: none; */
}

.scene-controls-panel {
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  z-index: 999;
}

/** replace ugly fat windows scrollbars with small semi-transparent ones (firefox has nice scrollbars out of the box) */
::-webkit-scrollbar {
  opacity: 0.5;
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: rgba(22, 22, 30, 0.646);
}

::-webkit-scrollbar-track-piece {
  background-color: #1a232a6e;
}

::-webkit-scrollbar-thumb {
  background: rgb(58, 62, 84);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(62, 65, 112);
}
</style>
