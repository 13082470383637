<template>
  <div class="crafting-produce">
    <div>
      <h4 class="crafting-produce-name">{{ $t(props.tokenMetadata.label) }}</h4>
      <div class="crafting-tabs">
        <div
          class="crafting-tabs-tab"
          :class="selectedTab === CraftingItemTab.RECIPE && 'active'"
          @click="() => handleClickTab(CraftingItemTab.RECIPE)"
        >
          {{ $t('craftingRecipeTab') }}
        </div>
        <div
          class="crafting-tabs-tab"
          :class="selectedTab === CraftingItemTab.CRAFTING && 'active'"
          @click="() => handleClickTab(CraftingItemTab.CRAFTING)"
        >
          {{ $t('craftingCraftingTab') }}
        </div>
      </div>
      <CraftingRecipe v-if="selectedTab === CraftingItemTab.RECIPE" :recipe-id="props.recipeId" />
      <CraftingRunningRecipes
        v-if="selectedTab === CraftingItemTab.CRAFTING"
        :token-metadata="props.tokenMetadata"
        :recipe-id="props.recipeId"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref } from 'vue';

import { type TTokensConfigItem } from '~/utils/constants';
import { CraftingItemTab } from '~/types/crafting';
import { CraftingRecipe, CraftingRunningRecipes } from '#components';

const selectedTab = ref(CraftingItemTab.RECIPE);

const props = defineProps<{
  recipeId: string;
  tokenMetadata: TTokensConfigItem;
}>();

const handleClickTab = (tab: CraftingItemTab) => {
  selectedTab.value = tab;
};
</script>

<style lang="scss">
.crafting {
  &-tabs {
    margin-top: 15px;
    display: flex;
    width: 100%;
    box-shadow: 2px 4px 5px 0px #00000040;

    &-tab {
      cursor: pointer;
      width: 100%;
      background: radial-gradient(59.02% 71.08% at 84.01% 85.82%, #040f14 0%, #081b25 100%);
      padding: 14px 0;
      font-size: 20px;
      font-family: Grenze;
      text-align: center;
      color: #fff;
      border: 2px solid #1e353e;

      &.active {
        background: linear-gradient(180deg, #0083ad 0%, #00354f 100%);
      }
    }
  }

  &-info {
    &-duration {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &-info-row {
    border-bottom: 2px solid #ffffff1a;
    padding: 5px 0px;

    display: flex;
    justify-content: space-evenly;

    @media screen and (max-width: 900px) {
      flex-direction: column;
      align-items: center;
    }

    p {
      color: #90989d;
      font-size: 20px;
      font-family: Grenze;
      font-weight: 500;
      margin-bottom: 0;

      span {
        color: #fff;
      }
    }

    &-time {
      font-weight: 500;
      font-size: 24px;
      color: #fff;

      &-ready {
        color: #49ddaa;
      }
    }
  }

  &-produce {
    background: #10364a;
    padding: 16px 0;
    height: 100%;
    border-radius: 15px;

    &-name {
      color: #fff;
      font-weight: 700;
      font-size: 26px;
      font-family: Grenze;
      margin-bottom: 0;
    }

    &-time {
      margin-bottom: 20px;

      color: #fff;
      font-weight: 400;

      font-size: 20px;
    }

    @media screen and (max-width: 900px) {
      max-height: 100%;

      &-button {
        width: auto;
      }
    }
  }

  &-buttons {
    margin-top: 17px;
    padding: 0 16px;
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;

    @media screen and (max-width: 900px) {
      justify-content: center;
    }

    & > * {
      margin-left: 10px;

      @media screen and (max-width: 900px) {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
}
</style>
